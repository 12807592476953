.checkoutPagecont {
  &.global {
    .checkoutContainer {
      max-width: 980px;
      margin: auto;
      justify-content: center;

      @media #{$breakpoint-below-tablet} {
        max-width: 375px;
      }
      .secure-checkout {
        letter-spacing: 0.5px;
      }
      .checkoutHeader {
        .leftPanel {
          max-width: 580px;

          @media #{$breakpoint-below-tablet} {
            max-width: 375px;
            padding: 0px;
            margin: auto;
            justify-content: center;
          }
        }
      }

      .checkoutBody {
        margin: auto;
        justify-content: center;

        .deliveryOptionsContent {
          .radioBtns label::before {
            border: 1.5px solid #494949;
          }
          .handItToMe-hr {
            margin-bottom: 20px;
          }
          .deliveryInstrs .textarea {
            @media #{$breakpoint-below-tablet} {
              padding: 8px 15.23px 8px 15.23px;
            }
            @media #{$breakpoint-above-tablet} {
              padding: 8px 28.7px 8px 14.83px;
            }
          }
        }

        .checkoutDescDetails .info-label {
          @media #{$breakpoint-below-tablet} {
            padding-left: 20px;
          }
        }

        .leftPanel {
          max-width: 580px;
          @media #{$breakpoint-below-desktop-above-tablet} {
            max-width: 58%;
          }
          @media #{$breakpoint-below-tablet} {
            max-width: 375px;
            padding: 0px;
            margin: auto;
            justify-content: center;
          }
        }

        .rightPanel {
          max-width: 380px;
          @media #{$breakpoint-below-desktop-above-tablet} {
            max-width: 40%;
          }

          @media #{$breakpoint-below-tablet} {
            max-width: 375px;
            padding: 0px;
            margin: auto;
            justify-content: center;
          }
        }

        .checkoutDescDetails {
          .row {
            @media #{$breakpoint-below-tablet} {
              margin-left: 0;
              margin-right: 0;
            }
          }
          button.checkout-sing-in {
            @media #{$mobile-only} {
              margin-top: 30px;
            }
          }

          .checkoutContactInfoContainer {
            @media #{$breakpoint-below-tablet} {
              padding: 0;
            }
          }

          .row.no-bg {
            background-color: #f8f7f500;
          }
          // .payment-info{
          //     @media #{$breakpoint-above-tablet} {
          //     height: 124px;
          //     }
          //     @media #{$breakpoint-below-tablet} {
          //         height: 174px;
          //         }
          // }
          .clock-img{
            padding-right: 8px;
            margin-left: 5px;
          }
          .content-text {
            font-weight: 700;
            @media #{$mobile-only} {
              font-weight: 700;
            }
          }
          .payment-au {
            .addPaymentContent {
              @media #{$breakpoint-above-tablet} {
                margin-top: 40px;
                margin-bottom: 0;
              }
              @media #{$breakpoint-below-tablet} {
                margin: 0 -5px;
              }
              .payButton {
                margin-top: 20px;
                margin-bottom: 10px;
                text-align: left;
                color: #20212440;
                background-color: $Inactive-Gray;
                border: none;
                text-decoration: none;
                cursor: no-drop;

                &.enabled {
                  background-color: #e4002b;
                  border: 1px solid #e4002b;
                  color: #fff;
                  cursor: pointer;
                }
              }
            }
          }

          .cart-notification {
            padding: 0px;
            height: auto;

            .notify-alert {
              margin-bottom: 0px;
              background: $Alert-Yellow;
              color: $Brand-Black;
            }
          }
          .contact-label {
            @media #{$breakpoint-above-tablet} {
              margin-top: 47px !important ;
            }

            @media #{$mobile-only} {
              padding-bottom: 20px;
            }
            @media screen and (max-width: 360px) {
              padding-left: 30px;
            }
          }
          .checkoutAmountDetails {
            &.mobile {
              background-color: #ffffff;
            }
          }
        }
        .contactInfoContent {
          margin-top: 40px;
          margin-bottom: 40px;
          @media #{$mobile-only} {
            margin-top: 0px;
          }
          & .contactInfo-button-checkout {
            margin: 1rem 0;
            @media #{$breakpoint-iphone-se-2020} {
              border: 1px solid #202124;
              box-shadow: none;
            }
          }
          & .editForm {
            max-width: 84% !important;
          }
        }
      }
    }

    .contact-label {
      &.catering {
        margin-bottom: 38px !important;
        @media #{$mobile-only} {
          margin-bottom: 0px !important;
        }
      }
    }

    .info-note {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: #494949;
      padding: 0;

      .link {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
      .link-span-box {
        text-decoration: underline;
        .link {
          text-decoration: none;
          font-weight: normal;
        }
      }
    }

    .deliveryInfoContent-button {
      font-family: $National2Regular;
      font-style: normal;
      text-transform: none;
      text-decoration-skip-ink: none;
      font-size: 14px;
      text-decoration: underline;
      font-weight: bold;
      padding: 0;
      box-shadow: none;
      color: #202124;
      border: none;
      background: #f8f7f5;
    }

    .material-input {
      .formElement {
        &.read-only {
          border: 0px;
          cursor: default;
        }
      }
    }

    .checkout-contact-edit-btn {
      height: fit-content;
      font-size: 12px;

      @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
        margin-left: 55.5%;
      }

      @media #{$breakpoint-ipad-12-pro-portrait} {
        margin-left: 55%;
      }

      @media #{$breakpoint-ipad-pro-12-landscape} {
        margin-left: -8px;
      }
    }

    .checkout-summary {
      .billDescription {
        .foodName {
          font-family: $National2Medium;
          font-weight: 500;
          margin-top: 5px;

          &.subDesc {
            font-family: $National2Regular;
            font-weight: 400;
            font-size: 12px;
            color: $Dark-Gray;
            line-height: 22px;

            ul {
              padding-left: 15px;
            }
          }
        }
      }
    }
    .checkout-summary-content {
      &-item {
        &-name {
          &-total {
            letter-spacing: 1px;
          }
        }
        &-value {
          &-total {
            letter-spacing: 1px;
          }
        }
      }
    }
    .checkout-summary-content-item {
      .checkout-summary-content-item-name {
        .checkout-discount-code {
          &.text-strike-out {
            text-decoration: line-through;
          }
        }
      }

      .checkout-summary-content-item-value {
        &.text-strike-out {
          text-decoration: line-through;
        }
      }
    }
  }
  .info-note {
    .link {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.payoption-button{
    .radioBtns {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        label {
            margin-bottom: 0;
            width: 100%;
            padding: 24px;
            cursor: pointer;
        }

        label:first-child {
            margin-bottom: 1rem;
        }

    label:first-child {
      margin-bottom: 1rem;
    }

    input[type='radio'] {
      display: none;
    }

        input[type='radio']:checked+label::after {
            border-radius: 11px;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 33px;
            left: 28px;
            content: ' ';
            display: block;
            background: #202124;
        }
        .tender-label {
            font-family: $National2Medium;
            font-weight: 500;
            color: $Brand-Black;
            font-size: 14px;
            line-height: 24px;
            margin-top: 2px;
            margin-bottom: 13px;
            padding-left: 10px;
        }
        .tender-image {
            float: right;
        }
    }
    &:last-child {
        border-bottom: none;
    }
}
.payoption-button:not(:last-of-type) {
    border-bottom: 1px solid #A6A6A7;
}
.payment-option-text{
    font-family: $National2TestMedium;
    font-size: 16px;
    font-weight: 500;
    line-height:24px;
    color: #202124;
    position: relative;
}
.form-checkbox-container{
    margin-top: 24px;
    
    // input.common_checkbox {
    //     // accent-color: #4D4D50;
    // }
    .common_checkbox{
        min-height: 0;
        max-height: 1rem;
        @-moz-document url-prefix() {
                margin-top: 4px !important;
            }
    }
    .tender-label {
      font-family: $National2Medium;
      font-weight: 500;
      color: $Brand-Black;
      font-size: 14px;
      line-height: 24px;
      margin-top: 2px;
      margin-bottom: 13px;
      padding-left: 10px;
    }
    .tender-image {
      margin-top: 4px;
      float: right;
    }
  }
.payment-option-text {
  font-family: $National2TestMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #202124;
  position: relative;
}
.form-checkbox-container {
  margin-top: 24px;

  // input.common_checkbox {
  //     // accent-color: #4D4D50;
  // }
  .common_checkbox {
    min-height: 0;
    max-height: 1rem;
    @-moz-document url-prefix() {
      margin-top: 4px !important;
    }
  }
  u {
    color: #202124;
  }
  u:hover {
    color: #202124;
  }
  p {
    margin-bottom: 0;
  }
}
.formElement-note {
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.1px;
  color: #202124;
  margin-top: 24px;
  display: inline-block;
}
.form-checkbox-text {
  a,
  p {
    font-family: $National2Regular;
    font-style: normal;
    font-size: 14px;
  }
  p {
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.1px;
    // color: #202124;
  }
  a {
    color: $Brand-Black;
    font-weight: bold;
    line-height: 22px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.wrapper-test {
  height: 100px;
  position: relative;
}
.options-checkboxes {
  position: absolute;
  bottom: 10%;
  left: 22px;
  padding-left: 15px;
}
.optional-description {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.1px;
  color: $gray-lightbg;
  margin-top: 8px;
  margin-left: 30px;
}
.notefieldelement {
  margin-top: 24px;
  font-size: 14px;
  font-family: $National2Regular;
}

.savedcard-option {
    @extend .payoption-button;
    margin-bottom: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.payment-tagline-text {
  font-style: normal;
  font-size: 16px;
  letter-spacing: -0.1px;
  color: #494949;
  margin-bottom: 14px;
  margin-left: 10px;
  font-weight: 500;
}

.payment-sa {
    margin-top: 47px;
    @media #{$breakpoint-below-tablet} { 
        margin-top: 0;
    }
    .payment-option-border {
        margin-bottom: 0;
        border-bottom: 1px solid #202124;
    }
    .payment-option-border:last-of-type {
        border-bottom: none;
    }
}
.payment-card-mask:not(:empty)::before {
    content: '\2022  \2022  \2022  \2022  ';
}
.change-payment {
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    box-shadow: none;
    color: #202124;
    border: none;
    background: #f8f7f5;
    cursor: pointer;
    @media #{$mobile-only} { 
        position: absolute;
        right: 0;
        top: -40px;
    }

}
.form-checkbox-payonarrival {
    margin-left: 15px;
    margin-top: 10px;
    .common_checkbox{
        margin-top: 0 !important;
    }
    .form-check-label {
        padding-left: 10px;
        font-weight: bold;
        cursor: pointer;
    }
    .common_checkbox:disabled+label {
        opacity: 0.5;
    }
}
.deliveryOptionsContent {
    .optional-description {
        margin-left: 15px;
    }
    .leaveAtMyDoorTitle, .handItToMe {
        font-weight: bold !important;
    }
}
.radiobutton {
    display: none;
}

.cash-on-delivery-checkox {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    .radioBtns {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        label {
            margin-bottom: 0;
            width: 100%;
            padding: 24px;
            cursor: pointer;
            font-weight: 500;
        }

        label:first-child {
            margin-bottom: 1rem;
        }

        input[type='radio'] {
            display: none;
        }

        label::before {
            content: none;
        }

        input[type='radio']:checked+label::after {
           content: none;
        }
        .tender-label {
            font-family: $National2Medium;
            font-weight: 500;
            color: $Brand-Black;
            font-size: 14px;
            line-height: 24px;
            margin-top: 2px;
            margin-bottom: 13px;
            padding-left: 10px;
        }
        .tender-image {
            float: right;
        }
    }
}


.credit-card-and-debitcard-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 24px;
    width: 280px;
    height: 520px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    label{
       font-weight: 500; 
    }
    .form-head-title{
        width: 248px;
        height: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202124;
    }
    input {
        border: none !important;
        border-bottom: 1px solid #202124 !important ;
        outline: none;
        width: 230px;
        background: transparent;
     }
    .form-checkox-text {
        input[type=checkbox]{
          width: 20px !important;   
          color: black;
        }
        font-weight: 600;
        span{
            margin-left: 10px;
        }
    } 
}

.cc-and-dc-form{
    padding-left: 24px;
    input[type=checkbox]{
        width: 20px !important;   
        color: black;
      }
      font-weight: 600;
    .form-check-label{
        margin: -14px 0 0 10px;
        display: inline-flex;
    }
    .notefieldelement{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202124;
    }
}
button[data-testid="pay-button-pay-pickup"] {
    margin-top: 10px;
}
